import React from 'react';
import { ActionTiles as Tiles, IActionTile, PictureContainer, Section } from '@belong/ui-components';
import { getResponsiveSrc } from '@belong/utils/typeHelper/image';
import { ISectionActionTiles } from './model';

const TileImage = (image, hasFullWidthImageTiles) => {
  const responsiveSrc = getResponsiveSrc(
    image,
    hasFullWidthImageTiles
      ? { xs: { w: 360 }, sm: { w: 800 }, md: { w: 400 } }
      : {
          xs: { w: 360, h: 260 },
          sm: { w: 800, h: 600 },
          md: { w: 520, h: 390 },
          lg: { w: 720, h: 510 },
          xl: { w: 1100, h: 510 }
        },
    hasFullWidthImageTiles ? { fit: 'scale', f: 'center' } : { fit: 'fill', f: 'face' }
  );
  return <PictureContainer src={responsiveSrc} alt={image.alt} contentType={image.contentType} />;
};

const SectionActionTiles: React.FC<ISectionActionTiles> = ({
  id,
  actionTiles,
  identifier,
  sectionStyles,
  hasFullWidthImageTiles
}: ISectionActionTiles) => {
  const newTiles: IActionTile[] = actionTiles.map(tile => ({
    ...tile,
    image: TileImage(tile.image, hasFullWidthImageTiles)
  }));

  return (
    <Section
      data-contentid={id}
      data-identifier={identifier}
      data-testid="section-action-tiles"
      isFullWidth
      {...sectionStyles}
    >
      <Tiles actionTiles={newTiles} hasFullWidthImageTiles={hasFullWidthImageTiles} />
    </Section>
  );
};

export default SectionActionTiles;
